import React, { useEffect } from "react"
import PropTypes from 'prop-types'
import Nav from './Nav';
import Footer from './Footer';
import AOS from 'aos'
import CookieConsent from "react-cookie-consent";
import 'aos/dist/aos.css'
const Layout = ({ children, className }) => {

  // Initialise the Animations for fade up and in
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <>
      <header className="bg-gray-100 h-16">
        <Nav />
      </header>
      <main className={"min-h-screen bg-gray-100 font-thin " + className}>
        {children}
      </main>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Ok, thanks!"
        cookieName="ueg-cookie"
        containerClasses="bg-primary"
        style={{background: ''}}
        buttonClasses="bg-gradient-l-golden rounded"
        buttonStyle={{ background:'', borderRadius: '' }}
        expires={150}
      >
        This website uses Google Analytics to collect anonymous data on how many people have visited the site.
      </CookieConsent>

    </>
  )
}

Layout.propTypes = {

}

export default Layout
