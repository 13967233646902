import Linked from '../elements/Linked'
import React from 'react'
import BookLogo from '@images/booklogo.png'
import { BOOK_URL } from '../../data/constants';




export default function Nav(props) {


    const [isAtTop, setIsAtTop] = React.useState(true)

    React.useEffect(() => {

        const monitorScroll = (scrollY) => {
            if (scrollY < 10 && !isAtTop) {
                setIsAtTop(true)
            }

            if (scrollY > 10 && isAtTop) {
                setIsAtTop(false)
            }
        }

        window.onscroll = () => monitorScroll(window.scrollY)

        return () => window.onscroll = false
    })
    const onBlogPost = typeof window !== "undefined" && window.location.pathname.includes('blog/')
    return (
        <nav className={`w-full py-3 text-base px-4 font-light fixed z-40 ${!isAtTop ? "bg-gray-100 shadow-2xl" : "bg-transparent"} transition-colors duration-200 `}>
            <div className="flex max-w-screen-lg mx-auto md:justify-between">
                <Linked className="self-center" linkTo="/">
                    <img className="w-6 md:w-10 self-center" src={BookLogo} alt="The Ultimate Essay Guide Book Logo" />
                </Linked>

                {onBlogPost &&
                    <Linked className="self-center md:ml-4 lg:ml-24 xl:ml-32 bg-gray-300 py-2 px-3 rounded bg-primary text-white" linkTo="/blog">
                        <i className="fa fa-arrow-left mr-2" />Back
                </Linked>}

                <ul className="flex ml-4 md:ml-auto w-full md:w-auto  ">
                    <li className="self-center  md:ml-auto mr-4 md:mr-10">
                        <Linked linkTo="/contents">Contents</Linked>
                    </li>
                    <li className="self-center md:ml-auto mr-2  md:mr-10">
                        <Linked linkTo="/blog">Blog</Linked>
                    </li>
                    <li className="ml-auto block">
                        <a href={BOOK_URL} target="_blank" rel="noopener noreferrer" >
                            <button className="border font-light text-primary border-primary hover:border-none bg-primary px-2  py-1 md:px-6 md:py-2 text-gold-light transition-all duration-200 rounded">Get Book</button>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}
