import React from 'react'
import PropTypes from 'prop-types'
import Link from '../elements/Linked';
import Linked from '../elements/Linked';
import { BOOK_URL } from '../../data/constants';


const Footer = props => {
    return (
        <div className="">
            <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-col md:flex-row flex-wrap justify-center">
                    <div className="px-5 py-2">
                        <Link className="text-base leading-6 text-gray-500 hover:text-gray-900" linkTo="/contents"  >

                            Contents
                        </Link>
                    </div>
                    <div className="px-5 py-2"  >
                        <Link linkTo={BOOK_URL} className="text-base leading-6 text-gray-500 hover:text-gray-900">
                            Get the book
                        </Link>
                    </div>
                    <div className="px-5 py-2">
                        <Link className="text-base leading-6 text-gray-500 hover:text-gray-900" linkTo="/blog"  >
                            Blog
                        </Link>
                    </div>
                </nav>
                <div className="mt-8">
                    <p className="text-center text-base leading-6 text-gray-400">
                        &copy; {new Date().getFullYear()} <Link linkTo="https://samloyd.io">Sam Loyd</Link>.  All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}

Footer.propTypes = {

}

export default Footer
